@import 'abstracts/variables';

// TODO: remove those (global!) styles #bootstrap

app-analytics-container,
.chart-container-content {
  * {
    @apply tw-font-sans #{!important};
  }

  /* Animate items as they're being sorted. */
  .cdk-drop-list-dragging .cdk-drag {
    filter: drop-shadow(0 2px 3px rgb(0 0 0 / 15%));
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .cdk-drag-placeholder {
    opacity: 0;
  }

  .preview-mode {
    .ag-header-cell-sortable,
    .ag-cell-value {
      pointer-events: none;
    }

    .ag-icon {
      opacity: 0.5;
    }
  }
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 100ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  filter: drop-shadow(0 3px 5px rgb(0 0 0 / 35%));

  .custom-drag-handle .icon {
    color: $secondary;
  }
}

app-analytics-container {
  .report-pdf-custom {
    @media print {
      .summary-table-container,
      .history-table-pdf-container {
        display: table;
      }

      .engagement-chart-custom-container,
      .performance-chart {
        width: 780px !important;
      }

      .custom-progress-info .custom-progress-bar {
        min-width: 160px !important;
      }

      html,
      body {
        height: 282mm;
      }

      tr {
        text-align: left;
      }

      tr td::before,
      tr td::after {
        content: '';
        height: 4px;
        display: block;
      }

      thead {
        display: contents !important;
        page-break-inside: avoid !important;
        break-inside: avoid !important;
      }
    }
  }
}

.analytics-hide-header {
  // FIXME: does not work
  .kp-header {
    display: none;
  }
}
