@import 'abstracts/variables';

.kp-import-excel-card-body {
  background-color: $white;
  border-radius: 20px;
  padding: 1.5rem;
  color: $main-color;

  .main-form {
    height: 350px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow-y: auto;
  }
}

.kp-upload-wrapper {
  background-color: #f8f8f8;
  border-radius: 80px;
}

.kp-excel-btn-upload {
  text-align: center;
  display: block;
  color: $accent-color;
  background-color: transparent;
  width: 100%;
  border: 0;
}

.kp-excel-btn-upload:hover {
  /* stylelint-disable-next-line scss/no-global-function-names */
  color: darken($accent-color, 20%);
}

.kp-excel-btn-upload-icon {
  width: 100px;
  height: 100px;
  font-size: 45px;
  border-radius: 50%;
  padding: 6px 12px;
}

.excel-file-return {
  margin: 0;
}

.kp-import-excel-subtitle {
  font-size: 14px;
  line-height: 19px;
  color: #bababa;
}

.kp-download-icon {
  color: $old-orange;
}

.kp-download-link,
%kp-download-link {
  padding: 15px;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: $main-color;
  text-decoration: none;
  background-color: $bg-color;
  border-radius: 30px;
}

.download-import {
  @extend %kp-download-link;

  padding: 12px;
  margin-bottom: 20px;
  border: 0;
}

%progress-btn {
  width: 45px;
  height: 45px;
  font-size: 20px;
  border-radius: 50%;
  border: 0;
  color: $white;
  display: block;
  cursor: pointer;
}

.progress-btn-active {
  @extend %progress-btn;

  background-color: $import-step-active;
}

.progress-btn-inactive {
  @extend %progress-btn;

  background-color: $import-step-inactive;
}

.progress-btn-complete {
  @extend %progress-btn;

  background-color: $import-step-complete;
}

.import-error-count {
  color: $old-red;
}

.successfully-completed-icon {
  font-size: 100px;
  color: $accent-color;
}

.successfully-completed-text {
  font-size: 18px;
}
