// Landing style
.landing-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-top: 80px;
}

.landing-card {
  max-width: 360px;
  min-width: 360px;
  padding: 20px 30px;
  margin: 20px 30px 0 0;
  background-color: $card-bg-color;
  border-radius: 8px;

  .card-title {
    margin-bottom: 30px;
    font-weight: bold;
    text-transform: uppercase;
    color: $primary;
    font-size: 32px;
  }

  .card-nav {
    padding: 0;
    list-style: none;
  }

  .card-link {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 16px;
    text-transform: uppercase;
    color: $primary;

    @include on-event {
      color: $secondary;
      text-decoration: underline;
    }

    &--last {
      margin-top: 10px;
    }
  }
}
