@tailwind base;

@layer base {
  html {
    @apply tw-h-screen;
  }

  body {
    // TODO: use a different background color?
    @apply tw-min-h-screen tw-bg-white tw-text-black;
  }

  // TODO: remove those (global!) styles #bootstrap

  .container {
    @apply tw-container tw-mx-auto tw-px-3;
  }

  .container-fluid {
    @apply tw-mx-auto tw-w-full tw-px-3;
  }

  .row {
    @apply -tw-mx-3 tw-flex tw-flex-wrap;

    > * {
      @apply tw-w-full tw-max-w-full tw-flex-shrink-0 tw-px-3;
    }
  }

  .col {
    @apply tw-flex-shrink-0 tw-flex-grow tw-basis-0;
  }

  .col-1 {
    @apply tw-w-1/12 tw-flex-shrink-0 tw-flex-grow-0 tw-basis-auto;
  }

  .col-2 {
    @apply tw-w-2/12 tw-flex-shrink-0 tw-flex-grow-0 tw-basis-auto;
  }

  .col-3 {
    @apply tw-w-3/12 tw-flex-shrink-0 tw-flex-grow-0 tw-basis-auto;
  }

  .col-4 {
    @apply tw-w-4/12 tw-flex-shrink-0 tw-flex-grow-0 tw-basis-auto;
  }

  .col-5 {
    @apply tw-w-5/12 tw-flex-shrink-0 tw-flex-grow-0 tw-basis-auto;
  }

  .col-6 {
    @apply tw-w-6/12 tw-flex-shrink-0 tw-flex-grow-0 tw-basis-auto;
  }

  .col-7 {
    @apply tw-w-7/12 tw-flex-shrink-0 tw-flex-grow-0 tw-basis-auto;
  }

  .col-8 {
    @apply tw-w-8/12 tw-flex-shrink-0 tw-flex-grow-0 tw-basis-auto;
  }

  .col-9 {
    @apply tw-w-9/12 tw-flex-shrink-0 tw-flex-grow-0 tw-basis-auto;
  }

  .col-10 {
    @apply tw-w-10/12 tw-flex-shrink-0 tw-flex-grow-0 tw-basis-auto;
  }

  .col-11 {
    @apply tw-w-11/12 tw-flex-shrink-0 tw-flex-grow-0 tw-basis-auto;
  }

  .col-12 {
    @apply tw-w-full tw-flex-shrink-0 tw-flex-grow-0 tw-basis-auto;
  }

  .col-md-1 {
    @apply md:tw-w-1/12 md:tw-flex-shrink-0 md:tw-flex-grow-0 md:tw-basis-auto;
  }

  .col-md-2 {
    @apply md:tw-w-2/12 md:tw-flex-shrink-0 md:tw-flex-grow-0 md:tw-basis-auto;
  }

  .col-md-3 {
    @apply md:tw-w-3/12 md:tw-flex-shrink-0 md:tw-flex-grow-0 md:tw-basis-auto;
  }

  .col-md-4 {
    @apply md:tw-w-4/12 md:tw-flex-shrink-0 md:tw-flex-grow-0 md:tw-basis-auto;
  }

  .col-md-5 {
    @apply md:tw-w-5/12 md:tw-flex-shrink-0 md:tw-flex-grow-0 md:tw-basis-auto;
  }

  .col-md-6 {
    @apply md:tw-w-6/12 md:tw-flex-shrink-0 md:tw-flex-grow-0 md:tw-basis-auto;
  }

  .col-md-7 {
    @apply md:tw-w-7/12 md:tw-flex-shrink-0 md:tw-flex-grow-0 md:tw-basis-auto;
  }

  .col-md-8 {
    @apply md:tw-w-8/12 md:tw-flex-shrink-0 md:tw-flex-grow-0 md:tw-basis-auto;
  }

  .col-md-9 {
    @apply md:tw-w-9/12 md:tw-flex-shrink-0 md:tw-flex-grow-0 md:tw-basis-auto;
  }

  .col-md-10 {
    @apply md:tw-w-10/12 md:tw-flex-shrink-0 md:tw-flex-grow-0 md:tw-basis-auto;
  }

  .col-md-11 {
    @apply md:tw-w-11/12 md:tw-flex-shrink-0 md:tw-flex-grow-0 md:tw-basis-auto;
  }

  .col-md-12 {
    @apply md:tw-w-full md:tw-flex-shrink-0 md:tw-flex-grow-0 md:tw-basis-auto;
  }

  .offset-1 {
    @apply tw-ml-[8.3333%];
  }

  .offset-2 {
    @apply tw-ml-[16.6667%];
  }

  .offset-3 {
    @apply tw-ml-[25%];
  }

  .offset-4 {
    @apply tw-ml-[33.3333%];
  }

  .badge {
    @apply tw-inline-block tw-whitespace-nowrap tw-rounded-md tw-px-[0.4875rem] tw-py-[0.2625rem] tw-text-center tw-align-baseline tw-text-12 tw-leading-none tw-text-white;
  }

  .form-control {
    @apply tw-block tw-w-full tw-appearance-none tw-bg-white tw-px-[0.75rem] tw-py-[0.375rem];

    &:disabled {
      @apply tw-bg-gray-light tw-opacity-100;
    }
  }

  .pagination {
    @apply tw-flex;
  }

  .table {
    @apply tw-mb-4 tw-w-full;

    > :not(caption) > * > * {
      @apply tw-p-2;
    }
  }

  .table-striped > tbody > tr:nth-of-type(odd) > * {
    @apply tw-bg-gray-very-light;
  }

  .body1 {
    @apply tw-text-[16px];
  }

  .body2 {
    @apply tw-text-[14px];
  }

  .label {
    @apply tw-text-[12px];
  }
}
