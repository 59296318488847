@use 'sass:map';
@import 'abstracts/variables';

// TODO: remove this file

$icon-colors: (
  'brand': $brand-color,
  'bright': $white,
  'dark': $black,
  'success': $green,
  'error': $red,
  'orange-light': $orange-light,
  'warning': $orange,
  'old-orange': $old-orange,
  'blue-light': $blue-light,
  'blue': $blue,
  'blue-dark': $blue-dark,
  'blue-very-dark': $blue-very-dark,
  'gray-light': $gray-light,
  'gray': $gray,
  'gray-dark': $gray-dark,
  'old-main': $main-color,
  'gray-very-dark': $gray-very-dark,
  'green-light': $green-light,
  'green-dark': $green-dark,
  'pale-blue': $pale-blue,
  'yellow-dark': $yellow-dark,
  'purple-bright': $purple-bright,
  'currentcolor': currentcolor,
);

.custom-icon {
  @each $colorName, $colorValue in $icon-colors {
    .icon-#{$colorName} {
      color: $colorValue;
    }
  }
}

// NOTE: Safari fix
@media not all and (resolution >= 0.001dpcm) {
  @media all {
    .custom-icon {
      .fa-icon > svg {
        vertical-align: middle;
      }
    }
  }
}
