// Search component style
@import 'abstracts/variables';

.search {
  background-color: transparent;
  border-radius: $border-radius;
  padding: 5px 10px;
  border: transparent;
  outline: none;
  color: $main-color;

  &--white {
    background-color: $white;
    border: $white;
  }

  &--grey {
    background-color: $bg-color !important;
    border: $bg-color !important;
  }
}

.search-inline {
  font-size: 14px;
  color: $main-color;
  width: 100%;
  outline: none;
  position: relative;
}
