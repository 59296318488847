@import 'abstracts/variables';
@import 'abstracts/mixins';

.attribute {
  &-container {
    background: $bg-color;
    margin-bottom: 10px;
    display: block;
    padding: 10px 7px;

    .label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 26px;

      h3 {
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 0;
        width: 72%;
      }
    }

    p {
      @include attribute-description;
    }

    &.is-for-weight {
      .attribute-type {
        text-transform: uppercase;
        text-align: left;
        color: #ccc;
        font-size: 8px;
        line-height: 10px;
      }
    }
  }

  &-type {
    font-size: 10px;
    text-transform: uppercase;
    text-align: right;
  }
}

.field-container {
  font-size: 13px;
  margin-bottom: 25px;
  position: relative;

  label,
  legend {
    font-size: 13px;
    margin-bottom: 0;
    line-height: 20px;
  }

  label {
    display: inline-block;
  }
}

.label,
.title,
.attribute,
.input,
.select {
  font-size: 13px;
  color: $main-color;
  line-height: normal;
}

.title {
  margin-bottom: 10px;

  &--uppercase {
    text-transform: uppercase;
  }

  &--medium {
    font-weight: normal;
  }

  &--bold {
    font-weight: bold;
  }
}

.clamp-text {
  position: relative;
  max-height: 35px;
  overflow: hidden;
}

/* Now add in code for the browsers that support -webkit-line-clamp and overwrite the non-supportive stuff */
@supports (-webkit-line-clamp: 2) {
  .clamp-text {
    height: auto;
    max-height: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .clamp-text::after {
    display: none;
  }
}

.attribute-q-container {
  margin-bottom: 50px;

  .attribute-q-label {
    color: $text-color;
    font-size: $large-font-size;
    margin-bottom: 20px;
  }

  .attribute-q-options-container,
  .attribute-q-input {
    border-radius: 4px;
    margin-bottom: 30px;

    .attribute-q-options {
      display: flex;
      gap: 8px;
      flex-direction: column;

      /* stylelint-disable-next-line selector-class-pattern */
      .response_score {
        position: absolute;
        right: -51px;
        width: 50px;
        height: 50px;
        background-color: $orange;
        color: $white;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }
    }
  }

  .attribute-q-input {
    border: 0;
    position: relative;

    .q-form-input {
      margin-bottom: 10px;
      height: 50px;
      font-size: $large-font-size;
      background: none;
      border: solid 1px $card-bg-color;
      border-radius: 4px;
      width: 100%;
      color: $primary;
    }

    .q-form-input.assessment-textarea {
      max-height: 100px;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .response_score {
      position: absolute;
      right: -51px;
      top: 0;
      width: 50px;
      height: 50px;
      background-color: $orange;
      border-radius: 4px;
      color: $white;
      margin-bottom: 3px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
  }

  .attrs-heading {
    display: flex;
    flex-direction: row;
    height: 50px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: solid 1px $card-bg-color;
    background-color: $white;

    .icon-boxed {
      color: $white;
      height: 48px;
      width: 48px;
      font-size: 30px;
      line-height: 1;
      background-color: $primary;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 20px;

      .title {
        font-size: 12px;
        color: $primary;
        text-transform: uppercase;
        margin-bottom: 3px;
        font-weight: bold;
      }

      .explanation {
        font-size: 10px;
        color: $text-color;
        font-style: italic;
      }
    }
  }
}

app-questionnaire-rating {
  .attribute-q-container {
    margin-bottom: 40px;

    .attribute-q-label {
      color: #323232;
    }

    .attribute-q-options-container,
    .attribute-q-input {
      .attribute-q-options {
        gap: 0;
        background: white;
      }
    }

    .attribute-q-input {
      .q-form-input {
        margin-bottom: 0;
        height: 30px;
        background: white;
        border: solid 1px #323232;
      }

      .q-form-input.assessment-textarea {
        min-height: 30px;
      }
    }
  }
}
