@use '@ag-grid-community/styles' as ag;
@import 'abstracts/variables';
@include ag.grid-styles(
  (
    theme: alpine,
    alpine-active-color: $secondary,
    input-focus-border-color: rgba($secondary, 0.4),
    invalid-color: $red,
    range-selection-border-color: $secondary,
  )
);

.ag-theme-alpine {
  .ag-checkbox-input-wrapper,
  .ag-icon {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: agGridAlpine !important;
  }

  .ag-pivot-mode-panel {
    display: none;
  }

  .ag-root-wrapper-body.ag-layout-normal {
    height: auto !important;
  }

  .ag-header-cell-menu-button {
    display: none;
  }

  .ag-filter-toolpanel-header {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ag-chart {
    .ag-chart-menu {
      display: block;
    }
  }

  .ag-group-child-count {
    display: none !important;
  }
}

.custom-ag-tooltip {
  @apply tw-font-sans #{!important};

  font-size: 14px !important;
  line-height: 21px !important;
}
