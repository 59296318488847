.aside-content-body {
  > app-aside-content-section,
  > app-divider {
    margin-bottom: 1.25rem;
  }

  > app-aside-content-section:last-of-type {
    margin-bottom: 0;
  }
}
