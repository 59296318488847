.custom-list-item-actions {
  align-items: center;
  display: grid;
  gap: 4px;
  grid-auto-flow: column;
  justify-content: end;

  .separator {
    background-color: rgba(0 0 0 / 20%);
    height: 0.875rem;
    width: 1px;
  }
}
