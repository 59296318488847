// TODO: remove those (global!) styles #bootstrap

@media print {
  * {
    print-color-adjust: exact;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }
}
