// App grid

// Main container
.main-container {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  background-color: transparent;

  @include respond-to('medium') {
    width: 1024px;
  }

  @include respond-to('large') {
    width: 1200px;
  }

  @include respond-to('extra-large') {
    width: 1280px;
  }

  // Full width class across all viewport and device sizes
  &--fluid {
    width: 100%;
  }

  // Background color
  &--sub-nav-bg-color {
    background-color: $sub-nav-bg-color;
    flex-direction: column;
  }

  &--toolbar-bg-color {
    background-color: $toolbar-bg-color;
  }

  &--accordion-bg-color {
    background-color: $accordion-bg-color;
  }

  &--form-header-bg-color {
    background-color: $form-header-bg-color;
  }

  &--sub-nav-assessments-bg-color {
    background-color: $cadet-blue;
  }
}

// Strech middle layout
.content {
  display: flex;
  flex-grow: 1;
}

.left-sidebar {
  // A fixed width as the default
  width: 240px;
}

.right-sidebar {
  // A fixed width as the default
  width: 300px;
}
