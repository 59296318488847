@import 'abstracts/variables';

.supplier-ratings-custom {
  .progress-radial {
    transition: 0.15s ease-in-out;

    .progress-radial-label {
      transition: 0.15s ease-in-out;
    }

    &:hover {
      cursor: pointer;

      .progress-radial-label {
        color: $secondary;
        transition: 0.15s ease-in-out;
      }
    }
  }
}
