@import 'abstracts/variables';

.custom-table {
  .mat-mdc-table {
    width: 100%;

    .mat-mdc-header-row {
      height: 30px;

      .mat-sort-header-content {
        text-decoration: underline;
      }
    }

    .mat-mdc-row {
      height: 33px;

      &.expanded-row {
        .mat-mdc-cell {
          border-bottom-width: 0;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
      }

      &.expanded-detail-row {
        height: 0;

        .mat-mdc-cell {
          padding: 0 !important;
          border-bottom-width: 0;
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;
        }

        &.expanded {
          .mat-mdc-cell {
            padding: 6px;
            border-bottom-width: 1px;
            border-bottom-style: solid;
          }
        }
      }
    }

    .mat-mdc-header-cell {
      font-size: 12px;
      line-height: 18px;
    }

    .mat-mdc-header-cell,
    .mat-mdc-cell {
      @apply tw-font-sans;

      color: $black;
      font-weight: normal;
      padding: 6px;
      letter-spacing: normal;
      -webkit-font-smoothing: auto;

      &:first-child {
        padding-left: 12px;
      }

      &:last-child {
        padding-right: 12px;
      }
    }

    .mat-mdc-header-cell,
    .mat-mdc-row:last-of-type .mat-mdc-cell {
      border-bottom-color: transparent;
    }
  }

  &-large-cell-padding .mat-mdc-table {
    .mat-mdc-header-cell,
    .mat-mdc-cell {
      padding: 12px;
    }
  }

  &-fancy-old {
    .mat-mdc-table {
      border-collapse: separate;
    }

    .mat-mdc-header-row {
      .mat-mdc-header-cell:first-of-type {
        padding-left: 20px;
      }

      .mat-mdc-header-cell:last-of-type {
        padding-right: 20px;
      }
    }

    .mat-mdc-row {
      .mat-mdc-cell:first-of-type {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding-left: 20px;
      }

      .mat-mdc-cell:last-of-type {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-right: 20px;
      }
    }

    .mat-mdc-header-cell {
      padding-bottom: 0 !important;
    }

    .mat-mdc-cell {
      background: $gray-very-light;
      border-bottom-color: transparent;
    }

    &.custom-table-version-200 .mat-mdc-table {
      .mat-mdc-header-cell,
      .mat-mdc-cell {
        padding: 0 12px;
      }
    }

    .cdk-drag-placeholder {
      .mat-cell {
        background: $gray;
      }
    }

    .cdk-drag-animating {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .cdk-drop-list-dragging .mat-row:not(.cdk-drag-placeholder) {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .cdk-drop-list-dragging .mat-mdc-row:not(.cdk-drag-placeholder) {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
  }

  &-fancy {
    .mat-mdc-table {
      border-collapse: separate;
    }

    .mat-mdc-header-row {
      .mat-mdc-header-cell {
        // NOTE: this color should be the same as the background behind the table
        background: #f7f2ea;

        // HACK: this is done to hide things behind the rounded corners when scrolling
        &::before {
          content: '';
          inset: 0;
          position: absolute;
          background: #e9e1d5;
          z-index: -1;
        }

        &:first-of-type {
          padding-left: 20px;

          &::before {
            border-top-left-radius: 16px;
          }
        }

        &:last-of-type {
          padding-right: 20px;

          &::before {
            border-top-right-radius: 16px;
          }
        }
      }
    }

    .mat-mdc-row {
      .mat-mdc-cell:first-of-type {
        padding-left: 20px;
      }

      .mat-mdc-cell:last-of-type {
        padding-right: 20px;
      }
    }

    .mat-mdc-header-cell {
      padding-top: 12px !important;
      padding-bottom: 12px !important;
    }

    .mat-mdc-cell {
      border-bottom-color: #ddd;
    }

    &.custom-table-version-200 .mat-mdc-table {
      .mat-mdc-header-cell,
      .mat-mdc-cell {
        padding: 0 12px;
      }
    }
  }
}

.cdk-drag-preview-fancy-old {
  border-radius: 8px;
  box-shadow:
    0 5px 5px -3px rgb(0 0 0 / 20%),
    0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%);
  background: $gray-very-light !important;
  z-index: 9999 !important;

  .mat-mdc-cell:not(:first-child, :last-child) {
    padding: 6px;
  }

  .mat-mdc-cell:first-child {
    border-radius: 8px;
  }
}
