@import 'abstracts/mixins';

.form-bg {
  background-color: $form-bg;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 25px 0;

  .form-basic {
    display: flex;
  }

  .form-logo {
    margin-right: 30px;

    .new-logo {
      width: 40px;
    }
  }

  .form-title {
    margin-bottom: 0;
    display: block;
    font-size: 18px;
    line-height: 18px;
    color: $primary;
  }

  .form-subtitle {
    margin-bottom: 0;
    display: block;
    font-size: $default-font-size;
    color: $secondary;
    text-transform: uppercase;
  }
}

.form-steps {
  width: 240px;
  background-color: $primary;
}

.main-form-dropdown-container {
  position: relative;
  display: inline;

  &::after {
    pointer-events: none;
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 40%;
    right: 5%;
    border-top: 6px solid #555;
    opacity: 0.5;
  }
}

.main-form-dropdown {
  width: 100%;
  height: 30px;
  border-radius: $radius-border;
  padding-left: 10px;
  font-size: $default-font-size;
  color: $text-color;
  background-color: $white;
  border: 1px solid $input-border-color;
  appearance: none;

  &:disabled {
    background-color: $disabled-color;
  }
}

.main-form-input {
  width: 100%;
  height: 30px;
  border-radius: $radius-border;
  border: 1px solid $input-border-color;
  background-color: $white;
  padding-left: 10px;
  font-size: $default-font-size;
  color: $new-input-text-color;

  &:disabled {
    background-color: $disabled-color;
  }
}

.form-content {
  width: 100%;
  padding: 20px 35px;
  background-color: $white;

  .form-content-title {
    margin-bottom: 20px;
    font-size: $title-font-size;
    color: $primary;
    text-transform: uppercase;
  }

  .form-content-subtitle {
    @include form-content-subtitle;
  }

  .form-info {
    display: block;
    font-style: italic;
    color: $new-input-text-color;

    &--default {
      font-size: $default-font-size;
    }

    &--small {
      font-size: 11px;
    }
  }

  .asterisk {
    color: $tertiary;
  }
}

.form-area {
  margin-top: 30px;

  .area-option {
    position: relative;
    margin-bottom: 15px;
  }

  .area-label {
    margin-left: 20px;
    font-size: 16px;
    line-height: 22px;
    color: $text-color;
    cursor: pointer;
  }

  .area-radio-btn {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
  }

  .area-checkmark {
    position: absolute;
    top: 2px;
    left: -10px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: $main-bg-color;
    cursor: pointer;
  }

  .area-radio-btn:checked ~ .area-checkmark {
    background-color: $secondary;
  }

  .area-radio-btn:disabled ~ .area-checkmark,
  .area-radio-btn:disabled ~ .area-label {
    cursor: not-allowed;
    color: $light-text-color;
  }
}

.form-preview {
  padding: 20px 0;

  .property-item {
    width: 100%;
    padding: 10px 20px;
    background-color: $main-bg-color;
    border-radius: $radius-border;
  }

  .item-name {
    display: block;
    margin-top: 20px;
    font-weight: bold;
    font-size: 14px;
    color: $primary;
    text-transform: uppercase;
  }

  .item-description,
  .item-type {
    display: block;
    color: $text-color;
  }

  .item-type {
    font-size: 12px;
  }

  .item-description {
    font-size: 14px;
  }

  .item-value {
    display: block;
    font-size: 14px;
    color: $text-color;
  }
}
