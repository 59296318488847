// Lists style
@import 'abstracts/variables';

.kp-list {
  margin-top: 40px;
}

/* stylelint-disable-next-line selector-class-pattern */
.kp-list__head {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  overflow-wrap: anywhere;
  word-break: break-word;
  margin-top: 40px;
  margin-bottom: 10px;
  padding: 10px;
  color: $main-color;

  &--modal {
    font-size: 12px;
    margin-bottom: 5px;
    text-align: left;
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.kp-list__body {
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  margin-bottom: 25px;
  padding: 10px;

  .col,
  .col-md {
    word-break: break-all;
    align-items: center;
    min-width: 0;
  }

  .supplier {
    position: relative;
  }

  .list-item {
    display: flex;
    width: 100%;
    padding: 10px;
    background-color: $white;
    text-align: center;
  }

  &--modal {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 0;
    cursor: pointer;
  }

  &--modal:hover {
    background-color: $table-hover-color;
  }
}

.striped {
  background-color: $table-stripe-color;
}

/* stylelint-disable-next-line selector-class-pattern */
.router_link {
  margin-bottom: 15px;
  cursor: pointer;
  background-color: $white;
  border-radius: $border-radius;
  font-size: 14px;

  > .col-center {
    padding: 8px;
    line-height: 28px;
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.router_link:focus {
  outline: none;
}

/* stylelint-disable-next-line selector-class-pattern */
.router_link:hover {
  background-color: $table-hover-color;
}

.kp-list-option-button {
  width: 35px;
  height: 35px;
  margin: 0;
  border-radius: 50%;
  border: 0;
  background-color: $white;
  box-shadow: $box-shadow;
  text-align: center;
  outline: none;
  cursor: pointer;
  color: $main-color;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.rating-high-risk {
  color: $highRisk;
}

.rating-early-warning {
  color: $earlyWarning;
}

.rating-good {
  color: $good;
}

.rating-excellent {
  color: $excellent;
}

.table-container {
  display: block;
  margin: 40px auto 0;
  width: 100%;
  max-width: 1200px;
}

.regular-content-container {
  display: block;
  margin: 30px auto 0;
  width: 100%;
  max-width: 1200px;
}

.flex-table {
  display: flex;
  flex-flow: row wrap;

  .flex-row {
    width: calc(100% / 3);
    text-align: left;
    padding: 15px 10px;
    background-color: $main-bg-color;
    font-size: $default-font-size;
    font-weight: normal;
    line-height: 19px;
    color: $text-color;

    &.last {
      text-align: right;
    }

    &.header {
      background-color: transparent;
    }

    &:first-child {
      border-top-left-radius: $list-item-border;
      border-bottom-left-radius: $list-item-border;
    }

    &:last-child {
      border-top-right-radius: $list-item-border;
      border-bottom-right-radius: $list-item-border;
    }
  }

  .row-button {
    padding: 0;
    border: 0;
    background-color: transparent;

    .button-text {
      padding-right: 10px;
      font-size: $default-font-size;
      color: $text-color;
    }

    &--delete {
      color: $high-risk-color !important;
    }
  }
}

.row-checkbox-label {
  margin: 0;
}

.row-checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 2px;
  border: solid 2px $checkbox-border;
  vertical-align: bottom;
  cursor: pointer;
}

.first-cell {
  display: flex;
  align-items: flex-start;
}

.cell-name {
  margin-left: 30px;
  display: flex;
  align-items: flex-start;
}

.row-name {
  padding-left: 10px;
  text-transform: uppercase;
  color: $primary;
  word-break: break-all;
}

.row-description {
  padding: 5px 20px;
  font-style: italic;
  margin: 0;
  margin-left: 30px;
}

.row-actions {
  margin: 0;
  padding: 0;
  list-style: none;
}

.action {
  display: inline-block;
  padding: 0 10px;

  .row-button {
    color: inherit;

    @include on-event {
      color: $secondary;
      text-decoration: underline;
      cursor: pointer;
    }

    &.warning {
      color: $old-red;
    }
  }

  &:not(:last-child) {
    border-right: 1px solid #d8d8d8;
  }
}
