@import 'abstracts/variables';

.custom-floating-toolbar {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 15%);
  display: flex;
  flex-direction: column;
  left: 25px;
  max-width: 400px;
  min-width: 300px;
  opacity: 0.75;
  overflow: hidden;
  position: fixed;
  top: 100px;
  transition: 0.25s ease-in-out;
  transition-property: opacity, box-shadow;
  z-index: 900;

  &.elevated {
    box-shadow: 0 6px 16px rgb(0 0 0 / 35%);
  }

  &:hover {
    opacity: 1;
  }

  * {
    @apply tw-font-sans;
  }

  .toolbar-header {
    background-color: $gray-very-light;
    cursor: all-scroll;
    padding: 16px;
  }

  .toolbar-content {
    padding: 16px;
  }

  .toolbar-buttons {
    display: grid;
    gap: 16px;
    grid-auto-flow: column;
    justify-content: end;
    padding: 16px;
  }
}
