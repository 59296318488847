@use 'sass:map';
@import 'abstracts/variables';

$loading-spinner-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'bright': $white,
  'dark': $black,
  'currentColor': currentcolor,
);

.custom-loading-spinner {
  @each $colorName, $colorValue in $loading-spinner-colors {
    &-#{$colorName} {
      .mat-mdc-progress-spinner circle {
        stroke: $colorValue;
      }
    }
  }
}
