@import 'abstracts/variables';

.custom-checkbox {
  width: 20px;
  height: 20px;
  appearance: none;
  padding: 0;
  border-radius: 0;
  vertical-align: middle;
  background-color: $white;
  background-image: url('../../../assets/img/check-solid.svg');
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center;
  border: 1px solid $main-color;
  box-shadow: 0 1px 6px #eee;
  transition: 0.3s ease;
  cursor: pointer;
  margin-right: 1rem;
}

.custom-checkbox:checked {
  background-color: $main-color;
  color: $white;
  border: 1px solid $white;
}

.custom-checkbox:disabled {
  opacity: 0;
}

.multiselect-item-checkbox input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.multiselect-item-checkbox input[type='checkbox']:focus + span::before,
.multiselect-item-checkbox input[type='checkbox']:hover + span::before {
  border-color: #9b9b9b;
  background-color: #f2f2f2;
}

.multiselect-item-checkbox input[type='checkbox']:active + span::before {
  transition-duration: 0s;
}

.multiselect-item-checkbox input[type='checkbox'] + span {
  position: relative;
  padding-left: 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  margin: 0;
  color: $main-color;
  display: block;
}

.multiselect-item-checkbox input[type='checkbox'] + span::before {
  box-sizing: content-box;
  content: '';
  color: $new-checkbox-border-color;
  position: absolute;
  top: 50%;
  left: 1px;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 1.5px solid $new-checkbox-border-color;
  text-align: center;
  transition: all 0.4s ease;
  border-radius: 2px;
}

.multiselect-item-checkbox input[type='checkbox'] + span::after {
  box-sizing: content-box;
  content: '';
  background-color: $new-checkbox-border-color;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox']:disabled + span::before {
  border-color: #ccc;
}

.multiselect-item-checkbox
  input[type='checkbox']:disabled:focus
  + span::before
  .multiselect-item-checkbox
  input[type='checkbox']:disabled:hover
  + span::before {
  background-color: inherit;
}

.multiselect-item-checkbox
  input[type='checkbox']:disabled:checked
  + span::before {
  background-color: #ccc;
}

/* stylelint-disable-next-line no-duplicate-selectors */
.multiselect-item-checkbox input[type='checkbox'] + span::after {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}

.multiselect-item-checkbox input[type='checkbox']:checked + span::after {
  content: '';
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox']:checked + span::before {
  animation: borderscale 200ms ease-in;
  background: $new-checkbox-border-color;
}

/* stylelint-disable-next-line no-duplicate-selectors */
.multiselect-item-checkbox input[type='checkbox']:checked + span::after {
  transform: rotate(-45deg) scale(1);
}

@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 1.5px $new-checkbox-border-color;
  }
}

.input-dropdown-container {
  position: relative;
  display: inline;

  &::after {
    pointer-events: none;
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 7px;
    right: 42px;
    border-top: 6px solid #555;
    opacity: 0.5;
  }

  &.margin-0::after {
    right: 12px;
  }
}

.input-dropdown {
  width: 210px;
  height: 30px;
  border-radius: $radius-border;
  padding-left: 10px;
  margin-right: 30px;
  font-size: $default-font-size;
  color: $text-color;
  background-color: $white;
  border: 1px solid $input-border-color;
  appearance: none;

  &.margin-0 {
    margin-right: 0;
  }

  &:disabled {
    opacity: 0.7;
  }
}

.input-dropdown::-ms-expand {
  display: none;
}
