.tox-tinymce-aux {
  z-index: 9999 !important;
}

/* stylelint-disable-next-line selector-class-pattern */
.tox .tox-edit-area__iframe {
  @apply tw-bg-transparent #{!important};
}

.tox-dialog {
  @apply tw-font-sans #{!important};
}

.tox-tinymce {
  border: 0 !important;
}

.tox-editor-header {
  @apply tw-font-sans #{!important};
}

/* stylelint-disable-next-line selector-class-pattern */
.tox .tox-collection__item-label {
  overflow-wrap: anywhere !important;
}

@media not all and (resolution >= 0.001dpcm) {
  @media all {
    /* stylelint-disable-next-line selector-class-pattern */
    .tox .tox-collection__item-label {
      word-break: break-all !important;
    }
  }
}
