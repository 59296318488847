// Accordion style

.accordion {
  position: relative;
  width: 100%;
  padding: 0 40px;
}

.accordion-list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  .accordion-item {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    border-bottom: 5px solid transparent;
    border-radius: 0;

    @include on-event {
      border-bottom: 5px solid $secondary;
      cursor: pointer;
    }

    .accordion-link {
      display: block;
      padding: 12px 20px;
      font-size: 12px;
      color: $accordion-item-color;
      line-height: 25px;
      text-transform: uppercase;
      text-decoration: none;
      text-align: left;
      word-wrap: break-word;

      @include on-event {
        color: $white;
      }
    }
  }
}

.accordion-toggle {
  position: absolute;
  right: 40px;

  .toggle {
    display: block;
    padding: 5px 10px;
    border: 0;
    background-color: $accordion-bg-color;
    font-size: $default-font-size;
    color: $accordion-item-color;
  }
}
