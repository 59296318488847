// Toolbar style

.toolbar {
  display: block;
  width: 100%;
  height: 130px;
  padding: 0 40px;
}

.toolbar-padding {
  padding: 0 40px;
}

.sub-title {
  margin: 0 30px 0 0;
  font-size: $subtitle-font-size;
  text-transform: uppercase;
  color: $subtitle-color;
}

.toolbar-actions {
  display: flex;
  justify-content: space-between;

  .create {
    display: flex;
    align-self: center;
    margin-top: 20px;

    .button {
      margin-right: 20px;
    }
  }

  .search-text {
    position: relative;
    margin-top: 20px;

    .fa-magnifying-glass {
      position: absolute;
      top: 7px;
      left: 5px;
      color: #ccc;
    }
  }
}

.toolbar-filter {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}
