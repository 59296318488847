/* stylelint-disable selector-class-pattern */

#hubspot-form {
  position: relative;

  .submitted-message {
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    font-size: 14px;

    p::first-line {
      line-height: 54px;
    }
  }
}

._hubspot-signup-container {
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 600px !important;
  max-height: 600px;
  overflow-y: auto;

  .hs-dateinput {
    position: relative;
  }

  .hs-form-booleancheckbox-display,
  .hs-form-radio-display,
  .hs-fieldtype-file {
    width: fit-content;
  }
}
