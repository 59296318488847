@import 'abstracts/variables';

.kp-upload-button-wrapper {
  overflow: hidden;
  display: block;
  padding: 15px;
  border: 1px solid $main-color;
  border-radius: 20px;
  text-align: center;

  img:hover {
    cursor: pointer;
  }
}

.logo-upload-input {
  font-size: 100px;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  width: 100px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: -1;
}

.kp-btn-upload {
  text-align: center;
  display: block;
  border: 1px solid $new-border-color;
  border-radius: 50%;
  color: $new-border-color;
  background-color: transparent;
  width: 80px;
  height: 80px;
  font-size: 45px;
  cursor: pointer !important;
}

.kp-upload-logo-title {
  font-weight: normal;
  font-size: 16px;
  color: $main-color;
}

.drop-mask {
  position: absolute;
  inset: 0;
  z-index: 99;
  border: 2px dashed $main-color;
  border-radius: 30px;
}

.drop-zone-active {
  position: relative;
  background: $bg-color;
  border-radius: 30px;
}
