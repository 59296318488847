// Forms style
@import 'abstracts/variables';
@import 'abstracts/mixins';

.form-label {
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  color: $main-color;
  display: block;
  text-align: center;
  padding-top: 35px;
  padding-bottom: 10px;
}

.form-input {
  background-color: $bg-color;
  outline: none;
  border: 0;
  border-radius: $border-radius;
  display: block;

  &--white {
    background-color: $white;
  }
}

%kp-action-info-input {
  font-size: 14px;
  line-height: 22px;
  min-width: 70%;
  padding: 8px;
  text-align: center;
  text-align-last: center;
  display: block;
}

/* stylelint-disable-next-line selector-class-pattern */
.kp-action__info {
  /* stylelint-disable-next-line selector-class-pattern */
  .kp-action__info__label {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .kp-action__info__input {
    @extend %kp-action-info-input;

    margin: 0 auto 15px;

    &::placeholder {
      text-align: center;
    }

    &--type {
      border: 1px solid $main-color;
      color: $main-color;
      font-weight: bold;
    }

    &--desc {
      width: 100%;
      text-align: center;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .kp-action__info__date {
    @extend %kp-action-info-input;
  }
}

// Auth forms
.form-group {
  position: relative;
  margin-bottom: 1rem;
  min-height: 85px;

  .form-input {
    display: block;
    width: 100%;
    padding: 20px;
    font-size: 18px;
    line-height: 1.5;
    background-clip: padding-box;
    border: 0;
    border-radius: 40px;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    text-transform: lowercase;
    text-align: center;
    outline: none;
    cursor: default;

    &--auth {
      color: $white;
      background-color: rgb(255 255 255 / 30%);

      &::placeholder {
        color: $white;
      }
    }

    &--change-password {
      color: $main-color;
      background-color: $white;

      &::placeholder {
        /* stylelint-disable-next-line scss/no-global-function-names */
        color: lighten($main-color, 30%);
      }
    }
  }

  &--last {
    min-height: 100px;
  }
}

%form-error {
  position: relative;
  font-size: 12px;
  line-height: 14px;
  color: $old-red;
  padding-top: 5px;
}

.form-error {
  @extend %form-error;
}

.property-form-error {
  @extend %form-error;

  max-width: 300px;
}

.form-btn {
  display: block;
  border: 0;
  border-radius: 40px;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  cursor: pointer;

  @include on-event {
    /* stylelint-disable-next-line scss/no-global-function-names */
    box-shadow: 0 0 6px darken($main-color, 20%);
  }

  &--auth {
    font-size: 16px;
    line-height: 1.5;
    padding: 10px 50px;
    color: $white;
    background-color: rgb(255 255 255 / 30%);
    background-clip: padding-box;
    text-align: center;
    margin: 40px auto 0;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: auto;
  }
}

.kp-inputs {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $main-color;
}

.is-invalid {
  border: 1px solid $old-red !important;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  @include on-event {
    /* stylelint-disable-next-line scss/no-global-function-names */
    box-shadow: 0 0 6px darken($old-red, 20%);
  }
}

/////////////////////////////
// REDESIGN SCSS VARIABLES
/////////////////////////////
.input-search {
  width: 280px;
  height: 30px;
  border-radius: $radius-border;
  padding-left: 40px;
  font-size: $default-font-size;
  color: $text-color;
  background-color: $white;
  border: 1px solid $input-border-color;

  &:disabled {
    opacity: 0.7;
  }
}
