/* stylelint-disable selector-class-pattern */

// NOTE:MM: temporary fix to avoid overlapping layers like modals or headers
._pendo-badge,
._pendo-badge_ {
  z-index: 1 !important;
}

@media print {
  ._pendo-badge,
  ._pendo-badge_ {
    display: none !important;
  }
}
