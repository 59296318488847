@import 'abstracts/variables';

.kp-list-pagination {
  .pagination-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    /* stylelint-disable-next-line selector-class-pattern */
    .kp-list-pagination__results {
      font-size: 12px;
      color: $main-color;
    }
  }

  /* stylelint-disable selector-class-pattern */
  .kp-list-pagination__nav {
    .kp-list-pagination__active,
    .kp-list-pagination__next,
    .kp-list-pagination__prev {
      /* stylelint-enable selector-class-pattern */
      a {
        color: $main-color;
        font-size: 12px;
        padding: 10px;
        line-height: 24px;
      }
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .kp-list-pagination__active {
    &.disabled-pointer-events {
      background-color: $main-color;
      border-radius: 50%;

      a {
        color: $white;
      }
    }
  }
}

.disabled-pointer-events {
  pointer-events: none;
}
