@import 'abstracts/variables';

.leaflet-container {
  background: #f5f7f8;

  * {
    @apply tw-font-sans #{!important};
  }

  a.leaflet-popup-close-button {
    width: 14px;
    padding: 2px 2px 0 0;
  }

  .leaflet-popup-content-wrapper {
    border-radius: 6px;
    box-shadow: 0 4px 4px rgba(0 0 0 / 10%);
    padding: 18px 4px 10px;
    min-width: 180px;
    max-width: 300px;
    box-sizing: content-box;

    .leaflet-popup-content {
      display: grid;
      gap: 8px;
      margin: 0;
      max-height: 300px;
      overflow-y: auto;
      padding: 0 4px;
      word-break: break-word;

      > * {
        margin: 0;
      }

      p {
        margin: 0 0 6px;
      }
    }
  }

  .leaflet-pane > svg path.leaflet-interactive {
    transition: fill 0.25s ease-in-out;
  }

  .leaflet-popup {
    .supplier-link {
      @apply tw-font-sans;

      display: block;
      border-radius: $button-border-radius;
      height: 30px;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 1px;
      line-height: 1.125rem;
      padding: 6px 12px;
      min-width: unset;
      text-align: center;
      text-transform: uppercase;
      background-color: $primary;
      color: $white;
    }
  }

  .leaflet-pane {
    z-index: 1;
  }

  .leaflet-div-icon {
    background: none;
    border: 0;
  }
}
