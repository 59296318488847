$cluster-background-color: rgb(44 61 79);

.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
  background-color: $white;

  div {
    background-color: $cluster-background-color;
  }
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  @apply tw-font-sans #{!important};

  width: 32px;
  height: 32px;
  margin-left: 4px;
  margin-top: 4px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  color: $white;
}

.marker-cluster span {
  line-height: 32px;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
  transition:
    transform 0.3s ease-out,
    opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  transition:
    stroke-dashoffset 0.3s ease-out,
    stroke-opacity 0.3s ease-in;
}
