@tailwind utilities;

@layer utilities {
  // TODO: remove
  [hidden] {
    display: none;
  }

  .tw-overflow-anywhere {
    overflow-wrap: anywhere;
    word-break: break-word;
  }
}
