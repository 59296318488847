// Notes style
@import 'abstracts/variables';
@import 'abstracts/mixins';

.notes {
  height: 400px;
  overflow-y: auto !important;
  padding: 0 6px;
  width: 100%;
}

.new-note {
  display: block;
  width: 100%;
  padding: 15px 20px;
  margin-top: 20px;
  background-color: $new-note-bg-color;
  border-bottom: $card-input-border;

  .note-heading {
    display: flex;
    justify-content: space-between;

    .new-note-heading {
      margin-bottom: 0;
      font-size: 12px;
      color: $text-color;
    }

    .discard-note {
      margin-right: 20px;
      background-color: transparent;
      border: 0;
      color: $high-risk-color;
      cursor: pointer;

      @include on-event {
        color: $high-risk-color;
      }
    }

    .save-note {
      background-color: transparent;
      border: 0;
      color: $excellent-color;
      cursor: pointer;

      @include on-event {
        color: $excellent-color;
      }
    }
  }
}

.note {
  display: block;
  padding: 20px;
  background-color: $note-bg-color;
  border-bottom: $card-input-border;

  .note-email,
  .note-timestamp {
    display: block;
    font-size: 12px;
    color: $text-color;
  }

  .note-timestamp {
    margin-bottom: 10px;
  }
}

.note-text {
  margin: 10px 0;
  display: block;
  font-size: $default-font-size;
  color: $text-color;

  textarea {
    width: 100%;
    border: 0;
    padding: 10px;
    background-color: $new-note-bg-color;
    font-size: $default-font-size;
    color: $text-color;
  }
}

@media print {
  .notes {
    height: auto;
  }
}
