@import 'abstracts/variables';
@import 'abstracts/mixins';

// TODO: remove those (global!) styles #bootstrap

.kp-title {
  font-weight: bold;
  font-size: 39px;
  line-height: 30px;
  color: $main-color;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.col-center {
  text-align: center;
}

.col-first {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

.col-last {
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}
