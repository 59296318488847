@import 'abstracts/variables';

// TODO: remove those (global!) styles #bootstrap

.pie-chart-skeleton {
  .custom-skeleton {
    height: 100%;
    width: 100%;
  }

  span.skeleton-loader.circle::after {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: calc(100% - 19px);
    height: calc(100% - 19px);
    background: $white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    content: '';
  }
}
