// TODO: remove all variables #bootstrap

// Sass variables

// Colors
$brand-color: #ecae71;
$main-color: #2c3d4f;
$accent-color: #52f7aa;
$bg-color: #f8f8f8;
$bg-color-dark: #e5e5e5;
$border-color: #e1e1e1;
$table-stripe-color: rgb(0 0 0 / 5%);
$table-hover-color: #ededed;
$dropdown-username-color: #72879d;
$card-header: #efefef;
$card-title: #b08c6b;
$item-border-color: #d8d8d8;
$old-orange: #ebad70;
$old-gray: #919599;
$old-red: #eb585d;
$checkbox-border-color: #f3f3f3;
/* stylelint-disable-next-line scss/no-global-function-names */
$dark-red: darken($old-red, 20%);
$inactive: #c5c5c5;
$pale-blue: #606c82;

// ========================================================
//  USE THIS VARIABLES  ↓
// ========================================================
$black: #323232;
$white: #fff;
$gray-very-dark: #616161;
$gray-dark: #989898;
$gray: #b8b8b8;
$gray-light: #ddd;
$gray-very-light: #f1f1f1;
$blue: #4bade8;
$blue-dark: #2893d3;
$blue-very-dark: #0b69a1;
$blue-light: #7dceff;
$green: #63ba3c;
$green-dark: #459f3d;
$green-light: #85d761;
$yellow: #ffe91f;
$yellow-dark: #ecd400;
$yellow-light: #fff27e;
$orange: #ff991f;
$orange-dark: #e88815;
$orange-light: #ffa841;
$red: #fa4534;
$red-dark: #da3d2d;
$red-light: #ff6f61;
$violet: #d951ef;
$violet-dark: #b852c9;
$violet-light: #e08cee;
$purple-bright: #552387;
$button-border-radius: 4px;

// ========================================================
//  USE THIS VARIABLES  ↑
// ========================================================

// Border radius
$border-radius: 20px !default;
$border-nav-radius: 5px;

// Box shadow
$drop-shadow: 0 1px 10px rgb(0 0 0 / 14%);
$box-shadow: 0 1px 10px 0 rgb(0 0 0 / 14%) !default;
$modal-box-shadow: 0 1px 10px 0 rgb(62 62 62 / 20%);
$box-shadow-flag: 0 1px 10px 0 $old-orange !default;

// Status
$status-new: #ecae71;
$status-pending: #3aeee6;
$status-ongoing: #6299ba;
$status-verified: #52f7aa;
$status-incomplete: #eb585d;
$filter-list-hover: #516172;
$filter-list-checked: #556c85;
$excellent: #94f8ca;
$good: #ccfffd;
/* stylelint-disable-next-line scss/dollar-variable-pattern */
$earlyWarning: #f7d3b0;
/* stylelint-disable-next-line scss/dollar-variable-pattern */
$highRisk: #ef7d81;

// Import excel colors
$import-step-inactive: #c5c5c5;
$import-step-active: #ecae71;
$import-step-complete: #4bb986;

// New design colours
$new-checkbox-border-color: #9b9b9b;

/////////////////////////////
// REDESIGN SCSS VARIABLES
/////////////////////////////

// Font size
$default-font-size: 14px;
$large-font-size: 16px;
$small-font-size: 13px;
$extra-small-font-size: 10px;
$nav-item-font-size: $default-font-size;
$sub-nav-item-font-size: $default-font-size;
$title-font-size: 30px;
$subtitle-font-size: 26px;
$total-rating-font-size: 46px;

// Color palette
$primary: #2c3d4f !default;
$secondary: #ebad70;
$tertiary: #ae8a68;
/* stylelint-disable-next-line scss/no-global-function-names */
$dark-primary: darken($primary, 20%);
/* stylelint-disable-next-line scss/no-global-function-names */
$dark-secondary: darken($secondary, 20%);

// Rating colors
$high-risk-color: #e5493a;
$excellent-color: #63ba3c;
$early-warning-color: #ff991f;
$good-color: #4bade8;

// Other colors
$white: #fff;
$cadet-blue: #5e6c84;
$magenta: #b22f93;
$tooltip-bg-color: #919599; // #616161 - angular material tooltip color

// Border
$default-border: 1px solid #ccc !default;
$radius-border: 4px !default;
$input-border-color: #ccc;
$form-input-border-color: #ddd;
$checkbox-border: #9b9b9b;
$list-item-border: 6px;
$card-border: 2px;

// Layout colors
$main-bg-color: #f1f1f1;
$nav-bg-color: $white;
$sub-nav-bg-color: $primary;
$toolbar-bg-color: $main-bg-color;
$accordion-bg-color: $cadet-blue;
$card-bg-color: #f1f1f1;
$form-header-bg-color: #fff;
$supplier-toolbar-bg-color: #fafafa;
$new-note-bg-color: #f9f9f9;
$note-bg-color: $white;
$disabled-color: #ebebe4;
$form-bg: #f9f9f9;

// Typography colors
$text-color: #555 !default;
$light-text-color: #999;
$nav-item-color: $primary;
$sub-nav-item-color: $white;
$accordion-item-color: $white;
$title-color: $white;
$subtitle-color: $tertiary;
$link-color: $secondary;
$turquoise: #0dcfda;
$new-border-color: #ddd;
$new-input-text-color: #484a4d;
$sidebar-background-color: #f9f9f9;
$card-input-color: #f9f9f9;
$card-input-border: 1px solid #e6e6e6;

// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'medium': (
    min-width: 1024px,
  ),
  'large': (
    min-width: 1200px,
  ),
  'extra-large': (
    min-width: 1280px,
  ),
) !default;

// Sizes
$navbar-height: 70px;
$module-header-height: 100px;
