@import 'abstracts/variables';

// TODO: remove all mixins #bootstrap

// Sass mixins

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:active,
    &:focus,
    &:hover {
      @content;
    }
  } @else {
    &:active,
    &:focus,
    &:hover {
      @content;
    }
  }
}

@mixin attribute-description() {
  font-size: 12px;
  margin: 0;
  padding: 2px 4px;
  color: $dropdown-username-color;

  span {
    color: #ccc;
    margin-right: 2px;
  }
}

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
/* stylelint-disable scss/function-unquote-no-unquoted-strings-inside, scss/no-global-function-names */
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}
/* stylelint-enable scss/function-unquote-no-unquoted-strings-inside, scss/no-global-function-names */

@mixin set-font-color($color) {
  color: transparent;
  text-shadow: 0 0 $color !important;
}

@mixin tree-checkbox() {
  input[type='checkbox'] {
    position: relative;
    width: 14px;
    height: 14px;
    color: #000;
    border: 1px solid $checkbox-border;
    border-radius: 2px;
    appearance: none;
    outline: 0;
    cursor: pointer;

    &::before {
      position: absolute;
      content: '';
      display: block;
      top: -1px;
      left: 4px;
      width: 5px;
      height: 12px;
      border-style: solid;
      border-color: $white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 0;
    }

    &:checked {
      color: $white;
      border-color: $checkbox-border;
      background: $checkbox-border;

      &::before {
        opacity: 1;
      }

      ~ label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }

    &:disabled {
      opacity: 0.3;
    }
  }
}

@mixin q-option($bg-color, $border-color) {
  min-height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &:last-of-type {
    border-bottom: 0;
  }

  label {
    width: 100%;
    display: block;
    text-align: left;
    color: $text-color;
    cursor: pointer;
    transition: color 200ms ease-in;
    height: 100%;
    padding: 15px 60px;
    font-size: 16px;
    margin-bottom: 2px;
  }

  input:checked ~ label {
    background-color: $bg-color;
  }

  input[type='radio'],
  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    border: 0;
    appearance: none;
    visibility: hidden;
    left: 20px;
    top: 15px;

    &::after {
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      position: absolute;
      content: '';
      display: inline-block;
      visibility: visible;
      border: 1px solid $card-bg-color;
    }

    &:checked::before {
      position: absolute;
      content: '';
      display: inline-block;
      visibility: visible;
    }

    &:checked::after {
      position: absolute;
      content: '';
      display: inline-block;
      visibility: visible;
      border: 0;
    }
  }

  input[type='radio'] {
    &::after {
      border-radius: 100%;
      background-color: $white;
    }

    &:checked::before {
      border-radius: 100%;
      border: 1px solid $border-color;
      background-color: $white;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
    }

    &:checked::after {
      border-radius: 100%;
      background-color: $border-color;
      width: 16px;
      height: 16px;
      top: 2px;
      left: 2px;
    }
  }

  input[type='checkbox'] {
    &::before {
      border-style: solid;
      border-color: $white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      width: 8px;
      height: 15px;
      top: 1px;
      left: 6px;
      z-index: 1;
    }

    &::after {
      background-color: $white;
      border-radius: 4px;
    }

    &:checked {
      &::after {
        opacity: 1;
        width: 20px;
        height: 20px;
        background-color: $border-color;
      }

      &::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }
}

@mixin fields-container() {
  display: flex;
  flex-direction: column;
}

@mixin explanation-text() {
  font-size: $extra-small-font-size;
  opacity: 0.6;
  min-height: 21px;
  font-style: italic;
  margin-bottom: 10px;
  display: block;
}

@mixin form-content-subtitle {
  margin: 15px 0 20px;
  display: block;
  font-size: $default-font-size;
  color: $new-input-text-color;
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: 1px solid $main-bg-color;
}

@mixin details-style() {
  div {
    margin-bottom: 10px;
  }

  .name {
    text-transform: uppercase;
  }

  .id {
    font-size: 12px;
  }
}

@mixin charts-container() {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 20px 175px;

  .chart-container {
    flex-basis: 25%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .chart-name {
      font-size: 12px;
      color: $text-color;
    }
  }
}
