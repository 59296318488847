@import 'abstracts/variables';

.circle {
  height: 50px;
  line-height: 50px;
  width: 50px;
  border-radius: 50%;
  display: inline-block;
  font-size: 1rem;
}

.info-title {
  font-weight: bold;
  font-size: 1rem;
  color: $main-color;
}

.info-text {
  font-weight: bold;
  color: $main-color;
}

app-map * {
  @apply tw-font-sans;
}
