// Navigation style
// Sub-navigation style
.sub-nav {
  display: flex;
  justify-content: space-between;
  height: 50px;

  .main-title {
    align-self: center;
    margin: 0;
    padding-left: 40px;
    font-weight: bold;
    font-size: $title-font-size;
    text-transform: uppercase;
    color: $title-color;
    letter-spacing: 2px;
  }

  .sub-nav-list {
    display: flex;
    align-self: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .sub-nav-item {
    padding-right: 40px;
    font-size: $default-font-size;
    color: $sub-nav-item-color;
    text-transform: uppercase;
    text-decoration: none;

    &.active {
      font-weight: bold;
    }
  }
}
