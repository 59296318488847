@import 'abstracts/variables';

.filter-field-container {
  display: flex;
  flex-direction: column;

  .filter-label {
    margin-bottom: 5px;
    padding-left: 3px;
    font-size: $default-font-size;
    color: $text-color;
  }
}

.homepage-filters {
  .filter-label {
    color: $white;
  }
}

.property-q-container {
  display: flex;
  flex-direction: column;

  .property-q-label {
    color: $text-color;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 0;
    border-bottom: 1px solid $card-bg-color;
    margin-bottom: 10px;
  }

  .property-q-value {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: $large-font-size;
  }
}
