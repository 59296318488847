.table-container {
  .table-data-row form,
  .table-header {
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px #e6e6e6;

    .table-data-item,
    .table-header-item {
      flex: 1;
      padding: 13px 20px 10px;
      text-align: left;
      color: $text-color;
    }

    .table-header-item {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px;
    }

    .ordered {
      cursor: pointer;
    }

    .table-data-item {
      font-size: 13px;
    }
  }

  .table-data-row form:hover {
    background-color: #fafafa;
  }
}

@media print {
  .table-container {
    .table-header {
      .table-header-item,
      .table-data-item {
        @include set-font-color($text-color);
      }
    }
  }
}
